<template>
  <v-container class="mkt-content-container mt-5">
    <px-marketplace-view-header title="Indicadores"
                                :breadcrumbs="breadcrumbs"/>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" sm="4">
          <v-hover v-slot:default="{ hover }" open-delay="100">
            <px-indicador-card :elevation="hover ? 12 : 2"
                               :value="indicadores.APROVADA || 0"
                               color="success"
                               class="rounded-card"
                               description="compras aprovadas"
                               icon="mdi-currency-usd"/>
          </v-hover>
        </v-col>
        <v-col cols="12" sm="4">
          <v-hover v-slot:default="{ hover }" open-delay="100">
            <px-indicador-card :elevation="hover ? 12 : 2"
                               :value="indicadores.ENVIADA || 0"
                               color="warning"
                               class="rounded-card"
                               description="compras pendentes"
                               icon="mdi-cart"/>
          </v-hover>
        </v-col>
        <v-col cols="12" sm="4">
          <v-hover v-slot:default="{ hover }" open-delay="100">
            <px-indicador-card :elevation="hover ? 12 : 2"
                               :value="canceladasRecusadas"
                               color="error"
                               class="rounded-card"
                               description="compras canceladas"
                               icon="mdi-cancel"/>
          </v-hover>
        </v-col>
      </v-row>
      <v-row class="text-center align-center justify-center">
        <v-col cols="12" sm="12" md="12" lg="6">
          <v-hover v-slot:default="{ hover }" open-delay="100">
            <px-dashboard-savings :elevation="hover ? 12 : 2"/>
          </v-hover>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="6">
          <v-hover v-slot:default="{ hover }" open-delay="100">
            <px-dashboard-compras :elevation="hover ? 12 : 2"/>
          </v-hover>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import IndicadoresService from './dashboard-service';
import DashboardSavings from './DashboardSavings.vue';
import DashboardCompras from './DashboardCompras.vue';

export default {
  data() {
    return {
      loading: false,
      indicadores: null,
      indicadoresCompras: null,
      breadcrumbs: [
        { text: 'Indicadores' },
      ],
    };
  },
  computed: {
    canceladasRecusadas() {
      return (this.indicadores.CANCELADA || 0) + (this.indicadores.RECUSADA);
    },
  },
  methods: {
    getIndicadores() {
      this.loading = true;
      IndicadoresService.getIndicadoresCompras()
        .then((response) => {
          this.indicadores = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getIndicadores();
  },
  components: {
    pxDashboardSavings: DashboardSavings,
    pxDashboardCompras: DashboardCompras,
  },
};
</script>

<style>
  @media (max-width: 1260px) {
    figure {
      width: 100%;
      margin: 1em auto;
      padding: 0 1em;
      border: none;
      border-radius: 0;
      box-shadow: none;
    }

    figure .echarts {
      width: 100%;
      min-width: 0;
      height: 40vw;
    }
  }

  @media (min-width: 980px) {
    figure.half {
      padding: 1em 1.5em;
      min-width: calc(240px + 3em);
    }
    figure.half .echarts {
      width: 28vw;
      min-width: 240px;
      height: 180px;
    }
    figure.half:not(:last-child) {
      margin-right: 15px;
    }
  }

  @media (max-width: 980px) {
    figure {
      width: 90vw;
      margin: 1em auto;
      padding: 0 1em;
      border: none;
      border-radius: 0;
      box-shadow: none;
    }

    figure .echarts {
      width: 100%;
      min-width: 0;
      height: 75vw;
    }
  }

</style>
